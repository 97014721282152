import { MenuController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-sidemenu',
  templateUrl: './app-sidemenu.component.html',
  styleUrls: ['./app-sidemenu.component.scss'],
})
export class AppSidemenuComponent implements OnInit {
  public selectedIndex = 0;
  public testvar = 0;

  public appPages = [
    {
      title: 'Einsatzplanung2',
      url: '/einsatzplanung',
      icon: 'calendar'
    },

    {
      title: 'Mitarbeiter',
      url: '/mitarbeiter',
      icon: 'people'
    },

    {
      title: 'Projekte',
      url: '/projekte',
      icon: 'construct'
    },
  ]

    

  constructor(private menuCtrl: MenuController) { }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

}

<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title class="ion-text-center">Einsatz planen</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid fixed>
    <ion-row nowrap>
      <ion-col>
        <ion-list>


          <form [formGroup]="projectForm" (ngSubmit)="onSubmit()">
            <!-- <ion-item>
            <ion-label>Date</ion-label>
            <ion-datetime formControlName="date" displayFormat="MMM DD, YYYY"></ion-datetime>
          </ion-item> -->
            <!-- Zeitraum -->
            <ion-item-group>
              <ion-item-divider>
                <ion-label>Zeitraum</ion-label>
              </ion-item-divider>

              <ion-item>
                <ion-label>Beginn</ion-label>
                <ion-datetime-button datetime="DateTimeBegin"></ion-datetime-button>
                <ion-modal [keepContentsMounted]="true">
                  <ng-template>
                    <ion-datetime id="DateTimeBegin" #DateTimeBegin value={{calendar_select_init_local_iso}}
                      formControlName="StartDate" min="2018" max="2028" locale="de-DE" showDefaultButtons="true"
                      doneText="Fertig" cancelText="Abbruch" first-day-of-week="1" presentation="date">
                    </ion-datetime>
                  </ng-template>
                </ion-modal>
              </ion-item>

              <ion-item>
                <ion-label>Ende</ion-label>
                <ion-datetime-button datetime="DateTimeEnd"></ion-datetime-button>
                <ion-modal [keepContentsMounted]="true">
                  <ng-template>
                    <ion-datetime id="DateTimeEnd" #DateTimeEnd value={{calendar_select_init_local_iso}}
                      formControlName="EndDate" min="2018" max="2028" locale="de-DE" showDefaultButtons="true"
                      doneText="Fertig" cancelText="Abbruch" first-day-of-week="1" presentation="date">
                    </ion-datetime>
                  </ng-template>
                </ion-modal>
              </ion-item>
            </ion-item-group>
            <ion-item-group>

              <ion-item-divider>
                <ion-label>Projektauswahl</ion-label>
              </ion-item-divider>
              <ion-item>
                <ion-label>Projekt</ion-label>
                <ion-select formControlName="project" placeholder="Projekt" interface="popover">
                  <ion-select-option *ngFor="let project of projects"
                    [value]="project.id">{{project.name}}</ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item>

                <ion-label>Schicht</ion-label>
                <ion-select formControlName="schicht" placeholder="Schicht" interface="popover">
                  <ion-select-option *ngFor="let schicht of schichten"
                    [value]="schicht.id">{{schicht.name}}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-item-group>
            <ion-item>
              <ion-label position="floating">Bemerkung</ion-label>
              <ion-input formControlName="bemerkung" type="text"></ion-input>
            </ion-item>


            <ion-item *ngIf="showausfallGrund">
              <ion-label>Ausfallgrund</ion-label>
              <ion-select #grundselect formControlName="ausfallgrund" interface="action-sheet"
                value="ausfallgruende_array[0]" cancelText="Abbrechen" placeholder="Auswahl">


                <ion-select-option *ngFor="let grund of ausfallgruende_array"
                  value="{{grund.Nummer}} - {{ grund.Grund }}">
                  {{grund.Nummer}} - {{ grund.Grund }}

                  <!-- <ion-select-option *ngFor="let grund of ausfallgruende_array"> -->

                </ion-select-option>
              </ion-select>
            </ion-item>



            <div class="ion-text-center button-container">;
              <ion-button type="submit" [disabled]="projectForm.invalid">Speichern</ion-button>
              <ion-button (click)="cancel()">Abbrechen</ion-button>
            </div>
            <br>
            <div class="error-message ion-text-center warning-text">
              {{ projectForm.errors?.['endDateBeforeStartDate'] ? 'Enddatum darf nicht vor dem Startdatum liegen' : ''
              }}

            </div>
          </form>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
@Injectable({
  providedIn: 'root'
})
export class DynamicMenuService {
  private menuSubject: BehaviorSubject<any>;
  public menuObs: Observable<any>;
  private authenticationService: AuthenticationService
  appPages: { title: string; url: string; icon: string; }[];
  constructor() {
    this.menuSubject = new BehaviorSubject<any>(null);
    this.menuObs = this.menuSubject.asObservable();
  }
  public get getMenu() {
    return this.menuSubject.value;
  }
  public sendMenu(menu: Array<any>) {
    // console.log("sendMenu wir aufgerufen");
    this.menuSubject.next(menu)
  }

  create_menu() {
    let gruppe = this.authenticationService.userGruppe;
    if (gruppe == "admin") {
      this.appPages = [
        {
          title: "Einsatzplanung",
          url: "/tabs/einsatzplanung",
          icon: "calendar",
        },

        {
          title: "Mitarbeiter",
          url: "/mitarbeiter",
          icon: "people",
        },

        {
          title: "Projekte",
          url: "/projekte",
          icon: "construct",
        },
      ];
    } else if (gruppe == "ma") {
      this.appPages = [
        {
          title: "Einsatzplanung",
          url: "/tabs/einsatzplanung",
          icon: "calendar",
        },

        {
          title: "Mitarbeiter",
          url: "/mitarbeiter",
          icon: "people",
        },
      ];
    }
    else if (gruppe == "pl") {
      this.appPages = [
        {
          title: "Einsatzplanung",
          url: "/tabs/einsatzplanung",
          icon: "calendar",
        },

        {
          title: "Mitarbeiter",
          url: "/mitarbeiter",
          icon: "people",
        },
        {
          title: "Projekte",
          url: "/projekte",
          icon: "construct",
        },

      ];
    }
    this.sendMenu(this.appPages);
  }
}
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { format, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';
import DOMPurify from 'dompurify';


interface Schicht {
  Name: string;
  Kuerzel: string;
}

@Component({
  selector: 'app-eingabe-dp-modal',
  templateUrl: './eingabe-dp-modal.page.html',
  styleUrls: ['./eingabe-dp-modal.page.scss'],
})
export class EingabeDpModalPage implements OnInit {
  projectForm: FormGroup;
  projects: any[] = [];
  schichtenData: any = { /* data */ };
  basis_schichten: any[] = [];
  schichten: any[] = [];
  schichten_translate: any[] = [];
  calendar_select_init_local_iso: string;
  projekt_id: string = ''; // Die übergebene Projekt ID
  bemerkung: string = ''; // Die übergebene Bemerkung
  statusStr: string = ''; // Die übergebene StatusStr
  ausfallgruende: any = {}; // Die übergebenen Ausfallgründe
  ausfallgruende_array: any[] = [];
  showausfallGrund: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private modalctrl: ModalController,
    private navparams: NavParams) {
  }

  ngOnInit() {
    this.schichten_translate = [
      { 'from': 'normalschicht', 'to': 'Normalschicht' },
      { 'from': 'urlaub', 'to': 'Urlaub' },
      { 'from': 'krankheit', 'to': 'Krankheit' },
      { 'from': 'krank_ohne_au', 'to': 'Krank ohne AU' },
      { 'from': 'einarbeitung', 'to': 'Einarbeitung' },
      { 'from': 'verfuegbar', 'to': 'Verfügbar' },
    ];

    const { ma_daten, calendar_select_init, ProjektIDsGesamt, projekt_ids, projektnamen, schichtListeNachProjektID, statusliste, projekt_id, bemerkung, statusStr, ausfallgruende } = this.navparams.data;
    console.log(this.navparams.data);
    this.projekt_id = projekt_id;
    this.bemerkung = bemerkung;
    this.statusStr = statusStr;
    this.ausfallgruende = ausfallgruende;
    console.log('ausfallgruende', this.ausfallgruende);
    // console.log("ma_daten", ma_daten);
    console.log('projekt_id', this.projekt_id);
    console.log('SchitlisteNachProjektID: ', schichtListeNachProjektID);

    // const projectSchichten = schichtListeNachProjektID [this.projekt_id] || {}; // Schichten für das ausgewählte Projekt, nur die zusätzlichen Schichten
    // console.log('schichtListeNachProjektID', schichtListeNachProjektID);
    // Iterier über das Objekt schichtListeNachProjektID
    // for (const [key, value] of Object.entries(schichtListeNachProjektID)) {
    //   console.log(key, value);
    // }
    console.log('statusliste', statusliste);
    console.log('statusStr', this.statusStr);
    this.combineProjectData(projekt_ids, projektnamen);
    // console.log(this.projects)
    this.basis_schichten = statusliste.map(status => ({ id: status.ID, name: status.Name, Kuerzel: '' }));
    this.calendar_select_init_local_iso = this.getCalendarSelectInitLocalIso(calendar_select_init);
    Object.keys(ausfallgruende).forEach(ausfallgrund => {
      this.ausfallgruende_array.push(ausfallgruende[ausfallgrund])
    })
    // console.log('ausfallgruende_array', this.ausfallgruende_array);

    this.createForm(schichtListeNachProjektID);

  }

  combineProjectData(project_ids_array: string[], project_names: string[]) {
    for (let i = 0; i < project_ids_array.length; i++) {
      this.projects.push({ id: project_ids_array[i], name: project_names[i] });
    }
    console.log(this.projects);
  }

  getCalendarSelectInitLocalIso(calendar_select_init: Date): string {
    return new Date(calendar_select_init.getTime() - (calendar_select_init.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
  }

  createForm(schichtListeNachProjektID: any) {
    this.projectForm = this.formBuilder.group({
      StartDate: [this.calendar_select_init_local_iso, Validators.required],
      EndDate: [this.calendar_select_init_local_iso, Validators.required],
      // project: [this.projects[0]?.id || ''], // Die Projekt ID des ersten Projekts in der Liste
      // project: [this.projekt_id || ''],  // Die übergebene Projekt ID

      // ID des ersten Projekts in der Liste oder die übergebene Projekt ID
      // project: this.projekt_id ? [this.projekt_id || ''] : [this.projects[0]?.id || ''],

      project: this.projekt_id ? this.projekt_id : this.projects[0]?.id,

      schicht: [''],
      bemerkung: [this.bemerkung, [this.sanitizeInput]],

      ausfallgrund: [this.ausfallgruende_array[0].Nummer + ' - ' + this.ausfallgruende_array[0].Grund, Validators.required],
    }, { validators: this.endDateAfterStartDateValidator() },
    );
    // Pre-select the first project and its first schicht
    // Diese Funktion geht davon aus, dass das erste Projekt ausgewählt wird
    // if (this.projects[0]) {
    //   const firstProjectId = this.projects[0].id;
    //   this.updateSchichten(firstProjectId, schichtListeNachProjektID);
    // }

    // console.log('Projekt im Formular: ' + this.projekt_id ? this.projekt_id : this.projects[0]?.id)
    // Es muss jedoch entweder das erste Projekt ausgewählt werden oder die übergebene Projekt ID
    // Wenn die übergebene Projekt ID nicht existiert, dann wird das erste Projekt ausgewählt
    // Wenn die übergebene Projekt ID existiert, dann wird das übergebene Projekt ausgewählt
    if (this.projekt_id) {
      this.updateSchichten(this.projekt_id, schichtListeNachProjektID);
    } else {
      this.updateSchichten(this.projects[0].id, schichtListeNachProjektID);
    }


    this.projectForm.get('project').valueChanges.subscribe(projectId => {
      this.updateSchichten(projectId, schichtListeNachProjektID);
    });

    this.projectForm.get('schicht').valueChanges.subscribe(schichtId => {
      console.log('schichtId', typeof (schichtId));
      console.log('schichtId', schichtId);
      // Abhängig von der ausgewählten Schicht wird der Ausfallgrund angezeigt oder ausgeblendet
      if (schichtId === 4) {
        this.projectForm.get('ausfallgrund').enable();
        this.showausfallGrund = true;
      }
      else {
        this.projectForm.get('ausfallgrund').disable();
        this.showausfallGrund = false;
      }
    });
  }

  updateSchichten(projectId, schichtenData) {
    let schicht_initial;
    const projectSchichten = schichtenData[projectId] || {}; // Schichten für das ausgewählte Projekt, nur die zusätzlichen Schichten
    // console.log('projectSchichten', projectSchichten);
    const filteredSchichten = Object.entries(projectSchichten).map(([id, schicht]: [string, Schicht]) => ({
      id,
      name: schicht.Name,
      Kuerzel: schicht.Kuerzel,
    }));

    this.schichten = [...this.basis_schichten, ...filteredSchichten]; // Baissschichten + zusätzliche Schichten
    console.log('this.schichten', this.schichten);

    if (this.statusStr !== 'urlaub' && this.statusStr !== 'urlaub_beantragt') {
      // schicht_initial = this.schichten.find(schicht => schicht.name === this.statusStr)
      schicht_initial = this.schichten.find(schicht => schicht.name === this.statusStr) ? this.schichten.find(schicht => schicht.name === this.statusStr) : this.schichten[0];

      // Falls schicht existiert, stammt diese aus den zusätzlichen Schichten, da es für diese Schicht keine Übersetzung gibt
      // Falls schicht nicht existiert, stammt diese aus den Baissschichten
      if (!schicht_initial) {
        schicht_initial = this.schichten.find(
          schicht => schicht.name === this.schichten_translate.find(
            schicht_translate => schicht_translate.from === this.statusStr)
            .to);
        // console.log('schicht_initial', schicht_initial);
        // easier 
        // Find the translation object for the current status string
        // let schichtTranslate = this.schichten_translate.find(schicht_translate => schicht_translate.from === this.statusStr);

        // // If a translation object was found, find the corresponding schicht
        // let schicht;
        // if (schichtTranslate) {
        //   schicht = this.schichten.find(schicht => schicht.name === schichtTranslate.to);
        // }
      }
    }
    else if (this.statusStr === 'urlaub') {
      console.log('Treffer');
      schicht_initial = { id: 3, name: 'Abwesend', Kuerzel: '' };
    }
    else if (this.statusStr === 'urlaub_beantragt') {
      schicht_initial = { id: 4, name: 'Abwesend beantragt', Kuerzel: '' };
      this.showausfallGrund = true;
    }
    else {
      schicht_initial = this.schichten[0];
    }


    console.log('schicht_initial', schicht_initial);
    this.projectForm.patchValue({
      //  schicht: this.schichten[0]?.id || ''
      schicht: schicht_initial.id
    });


  }

  // updateSchichten(projectId, schichtenData) {
  //   const projectSchichten = schichtenData[projectId] || {}; // Schichten für das ausgewählte Projekt, nur die zusätzlichen Schichten
  //   console.log('projectSchichten', projectSchichten);
  //   const filteredSchichten = Object.entries(projectSchichten).map(([id, schicht]: [string, Schicht]) => ({
  //     id,
  //     name: schicht.Name,
  //     Kuerzel: schicht.Kuerzel,
  //   }));

  //   this.schichten = [...this.basis_schichten, ...filteredSchichten]; // Baissschichten + zusätzliche Schichten
  //   console.log('this.schichten', this.schichten);
  //   this.projectForm.patchValue({
  //     schicht: this.schichten[0]?.id || ''
  //   });
  // }

  change_display_date(isodate: string): string {
    return format(parseISO(String(isodate)), ' d.MMMyyyy', { locale: de });
  }

  onSubmit() {
    this.modalctrl.dismiss(this.projectForm.value);
    // Process the form data here
  }

  cancel() {
    this.modalctrl.dismiss(null);
  }

  sanitizeInput(input: FormControl) {
    const inputValue = input.value;

    // Apply your sanitization logic here, e.g., with DOMPurify
    const sanitizedValue = DOMPurify.sanitize(inputValue);

    if (sanitizedValue !== inputValue) {
      return { unsanitized: true };
    }

    return null;
  }

  endDateAfterStartDateValidator(): ValidatorFn {
    return (group: FormGroup) => {
      const startDateControl = group.get('StartDate');
      const endDateControl = group.get('EndDate');

      if (startDateControl && endDateControl) {
        const startDate = new Date(startDateControl.value);
        const endDate = new Date(endDateControl.value);

        if (endDate < startDate) {
          endDateControl.setErrors({ endDateBeforeStartDate: true });
        } else {
          endDateControl.setErrors(null);
        }
      }

      return null;
    };
  }
}
import { from } from 'rxjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AppSidemenuComponent } from './components/app-sidemenu/app-sidemenu.component';
import { ComponentsModule } from './components/components.module';
import { MenuDirective } from './directives/menu.directive';
import { MenuAdminComponent } from './components/menu-admin/menu-admin.component';
import { TokeninterceptorService } from './services/tokeninterceptor.service';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
// import { IonicStorageModule, Storage } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

// Dieser Import ist wichtig, da ansonsten angular-jwt eine Fehlermeldung NullInjectionError -> ...Storage erzeugt
import { Storage } from '@ionic/storage';

import { EinsatzplanungDirective } from './directives/einsatzplanung.directive';
import { NgCalendarModule } from 'ionic2-calendar';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';

import { fakeBackendProvider } from './_helpers/fake-backend';
import { AuthenticationService } from './services/authentication.service';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { DynamicMenuService } from './services/dynamic-menu.service';
import { appInitializer } from './_helpers/app_initializer';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { FilterEinsatzplanungService } from './services/filter-einsatzplanung.service';
// import { FilterMitarbeiterService } from './services/filter-mitarbeiter.service';
import { CustomRouteStrategy } from './custom-route-strategy';
import { CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';
import { TableModule } from 'primeng/table';
import { ServiceWorkerModule } from '@angular/service-worker';



export function getcookie(name: string) {
  let cookieName = `${name}=`;
  let cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
}

export function tokengetter(cookiename: string) {
  // export function tokengetter() {
  // In dieser Funktion wird später abgefragt ob als Webapp oder native App ausgeführt wird.
  // Entsprechend wird dann der Token geholt
  // Webapp: Cookie
  // Native App: IonicStorage
  // return localStorage.getItem('jwt_token');
  // let token = cookieService.get('jwt_token');
  let token = getcookie(cookiename);
  //console.log('TOKEN LAUTET (TOKENGETTER) ' + token);
  return token;
}

export function jwtOptionsFactory(storage: Storage, cookieService: CookieService) {
  console.log('MARKER !!!');
  return {
    // tokenGetter: () => localStorage.getItem('jwt_token'),
    tokenGetter: () => tokengetter('jwt_token'),

    // tokenGetter: () => storage.get('jwt_token'),
    whitelistedDomains: ['localhost:8080', 'alisa-sophie.de', 'debian-vm-superanton.localdomain', 'testing.superanton.org', 'superanton.localdomain', 'macserver.local'],
    allowedDomains: ['localhost:8080', 'alisa-sophie.de', 'debian-vm-superanton.localdomain', 'testing.superanton.org', 'superanton.localdomain', 'macserver.local'],
  }
}
registerLocaleData(localeEn);
registerLocaleData(localeDe);



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent, MenuAdminComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgCalendarModule,
    TableModule,
    // ComponentsModule,
    JwtModule.forRoot({
      config: {
        // ...
        throwNoTokenError: true
      },
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Storage]
      }
    }),
    IonicStorageModule.forRoot(),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
  ],
  providers: [
    // StatusBar,
    // SplashScreen,
    /*
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokeninterceptorService,
      multi: true
    },
    */
    //  fakeBackendProvider,
    CookieService,
    AuthenticationService,
    DynamicMenuService,
    FilterEinsatzplanungService,
    // FilterMitarbeiterService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: RouteReuseStrategy, useClass: CustomRouteStrategy },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }

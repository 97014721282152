import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieManagementService {

  constructor() { }

  setCookie(name: string, value: string, expires: number) {
    let expiresDate = new Date();
    expiresDate.setTime(expiresDate.getTime() + (expires * 24 * 60 * 60 * 1000));
    let expiresString = `expires=${expiresDate.toUTCString()}`;
    document.cookie = `${name}=${value};${expiresString};path=/`;
  }

  getCookie(name: string) {
    let cookieName = `${name}=`;
    let cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return null;
  }

  deleteCookie(name: string) {
    this.setCookie(name, '', -1);
  }
}

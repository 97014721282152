import { CalendarComponent } from 'ionic2-calendar';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class FilterEinsatzplanungService {
  private _storage: Storage
  public ProjektIDsGefiltert: Array<string>
  public MitarbeiterIDsGefiltert: Array<string>
  public StatusIdsGefiltert: Array<string>
  constructor(
    private storage: Storage,
  ) {
    this.initialize().then(() => {
      console.log("FilterProjectsService initialized");
    })
  }

  async initialize() {
    console.log("FilterEinsatzplanungService initialize");
    // Check if storage is already created
    if (!this._storage) {
      this._storage = await this.storage.create();
    }
    this.ProjektIDsGefiltert = (await this._storage.get('fpids')) ?? []
    this.MitarbeiterIDsGefiltert = (await this._storage.get('fmaids')) ?? []
    this.StatusIdsGefiltert = (await this._storage.get('stids')) ?? []
    // console.log("FilterProjectsService: " + this.ProjektIDsGefiltert);
    await this.updateFilterStatus(["1000"]);
  }

  async get_values() {
    // console.log("Filter aufgerufen");
    return await this._storage.get('fpids') ?? []
  }
  async updateFilterProjects(ProjectIDs: Array<any>) {
    // console.log("Update Filter Storage: " + ProjectIDs);
    // await this._storage.clear();
    this._storage.remove('fpids').then(() => {
      // console.log('Key fpids is removed from the storage');
    }).catch((error) => {
      console.error('Error removing key fpids from storage', error);
    });

    this.ProjektIDsGefiltert = [];
    ProjectIDs.forEach(id => {
      this.ProjektIDsGefiltert.push(id);
    });
    this._storage.set('fpids', ProjectIDs);
  }

  async updateFilterStatus(StatusIDs: Array<any>) {
    // console.log("Update Filter Storage: " + StatusIDs);
    // await this._storage.clear();
    this._storage.remove('stids').then(() => {
      // console.log('Key stids is removed from the storage');
    }).catch((error) => {
      console.error('Error removing key stids from storage', error);
    });

    this.StatusIdsGefiltert = [];
    StatusIDs.forEach(id => {
      this.StatusIdsGefiltert.push(id);
    });
    this._storage.set('stids', StatusIDs);
  }

  async updateFilterMitarbeiter(MitarbeiterIDs: Array<any>) {
    // console.log("Update Filter Storage: " + ProjectIDs);
    // await this._storage.clear();
    this._storage.remove('fmaids').then(() => {
      // console.log('Key fmaids is removed from the storage');
    }).catch((error) => {
      console.error('Error removing key fmaids from storage', error);
    });

    this.MitarbeiterIDsGefiltert = [];
    MitarbeiterIDs.forEach(id => {
      this.MitarbeiterIDsGefiltert.push(id);
    });
    this._storage.set('fmaids', MitarbeiterIDs);
  }



  async clear() {
    await this.storage.clear();

  }
}

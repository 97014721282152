import { AuthenticationService } from '../services/authentication.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

export function appInitializer(authenticationService: AuthenticationService) {
    return () => authenticationService.refreshToken()
        .pipe(
            // catch error to start app on success or failure
            catchError(() => of())
        );
}
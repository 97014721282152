<!--
<ion-menu contentId="main-content" type="overlay">
  <ion-content>
    <ion-list id="inbox-list">
      <ion-list-header>Inbox</ion-list-header>
      <ion-note>hi@ionicframework.com</ion-note>

      <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
        <ion-item (click)="selectedIndex = i" routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" [class.selected]="selectedIndex == i">
          <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
          <ion-label>{{ p.title }}</ion-label>
        </ion-item>
      </ion-menu-toggle>
    </ion-list>
  </ion-content>
</ion-menu>
-->
<p>App sidemenu works</p>
<ion-app>

  <!-- <ion-split-pane when="xl" contentId="main-content"> -->
  <!--
    <app-app-sidemenu></app-app-sidemenu>
-->
  <ion-menu #marker contentId="main-content" type="overlay">
    <ion-content>
      <ion-list id="inbox-list">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-list-header>Superanton</ion-list-header>
              <ion-note>SAM-Consulting GmbH</ion-note>
            </ion-col>
            <ion-col class="ion-text-right">
              <div></div>
            </ion-col>
          </ion-row>
          <ion-row>

            <ion-col>
              <div></div>
            </ion-col>
            <ion-col>
              <div>
                <ion-img src="assets/superanton-logo.png"></ion-img>
              </div>
            </ion-col>
            <ion-col>
              <div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>


        <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
          <ion-item (click)="selectedIndex = i" [routerLink]="[p.url]" lines="none" detail="false"
            [class.selected]="selectedIndex == i">
            <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
            <ion-label>{{ p.title }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <ion-list>
        <ion-item lines='none' routerLink="/settings-overview" (click)="close_menu()" routerDirection="forward">
          <ion-icon name="settings" slot="start"></ion-icon>
          <ion-label>Einstellungen</ion-label>
        </ion-item>
      </ion-list>
      <ion-list id="sidebar_footer">
        <ion-item lines="none" (click)="logout()">
          <ion-icon name="log-out-outline" slot="start"></ion-icon>
          <ion-label color="danger">Abmelden</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>

  <!--
    <ion-menu side="start" contentId="main-content">
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>Start Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-item>Menu Item</ion-item>
          <ion-item>Menu Item</ion-item>
          <ion-item>Menu Item</ion-item>
          <ion-item>Menu Item</ion-item>
          <ion-item>Menu Item</ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    -->

  <ion-router-outlet id="main-content"></ion-router-outlet>
  <!-- </ion-split-pane> -->
</ion-app>
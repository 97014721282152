// export const SERVER_URL = "http://localhost:8080";
// export const SERVER_URL = "http://alisa-sophie.de:90/php-tests";
// export const SERVER_URL = "https://alisa-sophie.de";
// export const SERVER_URL = "https://testing.superanton.org";
// export const SERVER_URL = "http://debian-vm-superanton.localdomain/superanton-dev/superanton";
// export const SERVER_URL = "http://superanton.localdomain/superanton-dev/superanton";
// export const SERVER_URL = "http://macserver.local/superanton-dev/superanton";
// export const SERVER_URL = "https://testing.superanton.org";
export const PUBLIC_TOKEN_KEY = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA0rmWXkORQZ4sudzGyObc
bvgFRXmwii99UGC6ZIVs7U0na2 + EbFMi23QDWnPHhrwOcDrYQcrxRkq6qXhUDuI7
RRU + lL / C5bml / KHB0BeenWjYQ8Rrfqy3qfyYlcbogEs4USpLlsVSJpfChucdXL5u
Mu1pCWPY1cH4UiBEcwjMmrdSK24PtyBriX9BtybNZbNIz / 2WNDi41HIIxs008jxv
ByoNK1Ji23NLfT0zlwCi236gC9JjbKmOXo0cQMldV9bCqraFof8y7gcZEAi + Tavz
P2XGfICLOkAfldoBQS8EDj / kVU45NW3y7Ag + E6L / +9JaZaDk47XmCfLriDnqzBWc
8KhrZV7NGqnu8rncw0lBL / LS5Ypgm8us3neamPLXg5uBLZSSV36ppjcuOa0VBQCC
xWN9veeffB + FRGn1cKJy7Yvunbo9CTfCSmRP / 2LMyqC4PdVbfkgTZe5uwwUWiXl +
    gkiCZ1t / +zksb9eRRUulDuOMlHukUGUKWQduKkaq98D3Xk / AS3J8nQoPD5K2jnnJ
hdCV21Jdo2y8AVgkV6QoCUngwYETTlzFlxvI3zV9V7vSYfu4XyW1Yqp1rFZp61wu
UxDKO / GJfY3K7BHV / akVhowNHrbYZgSa / T4eSTKgYW8ZlEUcl + JGZN57Joqk + zza
yGgqcFUiGAF0tKfr9tJpNvMCAwEAAQ ==
    ----- END PUBLIC KEY-----`;

export function getServerUrl(): string {
    const hostname = window.location.hostname;
    let serverUrl: string;

    switch (hostname) {
        case 'app.superanton.org':
            console.log('app.superanton.org');
            serverUrl = "https://testing.superanton.org"
            break;
        case 'macserver.local':
            console.log('macserver.local');
            serverUrl = "http://macserver.local/superanton-dev/superanton";
            break;

        case 'thinkpad-x270.local':
            console.log
            serverUrl = "https://thinkpad-x270.local/superanton-dev/superanton";
            break;

        case 'mac-mini-m1.local':
            console.log('mac-mini-m1.local');
            serverUrl = "http://mac-mini-m1.local/superanton-dev/superanton";
            break;
        default:

            console.log('default');
            console.log('macserver.local');
            serverUrl = "https://macserver.local/superanton-dev/superanton";
    }




    return serverUrl;
}
export const SERVER_URL = getServerUrl();
import { Injectable } from "@angular/core";
import { tap, map, first, take } from "rxjs/operators";
import { ReplaySubject, BehaviorSubject, Observable, Observer, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { SERVER_URL, PUBLIC_TOKEN_KEY } from "../../config";

import { Storage } from "@ionic/storage";
import { CookieService } from 'ngx-cookie-service';
import { CookieManagementService } from "./cookiemanagement.service";
import { User } from "../_models/user";
import { AuthenticationService } from "./authentication.service";
import { RSA, enc } from 'crypto-js';
// import * as crypto from 'crypto-js';

/**
 * Definiert httpHeaders als Objekt vom Typ HttpHeaders.
 * Dieses Objekt entält als Eigenschaften die Header Einträge
 */
let httpHeaders = new HttpHeaders({
  "Content-Type": "application/x-www-form-urlencoded",
  //  'Content-Type' : 'application/json',
  // 'Cache-Control': 'no-cache'
});

/**
 * Definiert das options-Objekt, welche für den httpClient.post Aufruf benötigt wird.
 */
let options = {
  headers: httpHeaders,
  withCredentials: true,
};

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public jwtTokenName = "jwt_token";
  public nachname = "nachname";
  public vorname = "vorname";
  authUser = new ReplaySubject<any>(1);
  tokenvalue: string;

  userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;

  // public jwtHelper = new JwtHelperService();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly storage: Storage,
    private readonly jwtHelper: JwtHelperService,
    private cookieService: CookieService,
    private cookiemgmt: CookieManagementService,
    private authenticationService: AuthenticationService,
  ) {
    this.userSubject = new BehaviorSubject<User | null>(null);
    this.user = this.userSubject.asObservable();
  }


  // Helper function

  // helper function decodes JWT with secret
  async decode_jwt_token(token) {
    const publicKey = `${PUBLIC_TOKEN_KEY}`;

    try {
      // Split the JWT token into header, payload, and signature
      const headerAndPayload = token.split('.')[0] + '.' + token.split('.')[1];
      const signature = token.split('.')[2];

      // Decode the base64-encoded payload
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      console.log("Base 64: " + base64);
      const jsonPayload = atob(base64);
      const payload = JSON.parse(jsonPayload);

      // Verify the signature with the RSA public key
      const buffer = new TextEncoder().encode(headerAndPayload);
      const signatureArray = new Uint8Array(Array.from(atob(signature), c => c.charCodeAt(0)));
      const pubKey = new Uint8Array(Array.from(atob(publicKey), c => c.charCodeAt(0)));
      const cryptoKey = await crypto.subtle.importKey(
        'spki',
        pubKey,
        {
          name: 'RSASSA-PKCS1-v1_5',
          hash: 'SHA-256',
        },
        false,
        ['verify']
      );
      const verified = await crypto.subtle.verify(
        'RSASSA-PKCS1-v1_5',
        cryptoKey,
        signatureArray,
        buffer
      );

      // If the signature is verified, log the decoded payload
      if (verified) {
        console.log(payload);
      } else {
        console.error('Invalid signature');
      }
    } catch (error) {
      console.error('Error:', error.message);
    }

  }
  public get userValue() {
    return this.userSubject.value;
  }

  /**
   * Dieses Funktion holt den Wert des Tokens.
   * Wird vom token.interceptor benutzt, um bei jedem Request den Token
   * als Authorization im Header zu senden
   */
  getToken() {
    return localStorage.getItem(this.jwtTokenName);
  }

  checkLogin() {
    // localStorage.setItem(this.jwtTokenName, 'dsfds')
    console.log("checkLogin() wird aufgerufen");
    console.log("Hole Token aus Speicher");
    const promise = new Promise((resolve, reject) => {
      resolve(localStorage.getItem(this.jwtTokenName));
    });
    promise.then((jwt) => {
      console.log(jwt);

      /**
       * wir rufen hier die next Methode des Subjects auf. Dh alle Oberver des Subject werden
       * ausgeführt. (Führt zum Weiterleiten zur Home-Page oder zur Login-Page)
       * In app.components.ts haben wir den authProvider subscribed, dh einen Oberver zum Subject hinzugefügt.
       * (Bei einem Subject führt ein subscribe nicht zum Auführen sondern zum hinzufügen eines Observers)
       */
      if (jwt && !this.jwtHelper.isTokenExpired(<any>jwt)) {
        this.authUser.next(jwt);
      } else {
        /**
         *  falls es keinen gespeicherten Token gibt oder dieser abgelaufen ist, wird er gelöscht
         * und die Login-Seite wird aufgerufen
         */
        // falls es keinen gespeicherten Token gibt oder dieser abgelaufen ist, wird er gelöscht
        // und die Login-Seite wird aufgerufen
        localStorage.removeItem(this.jwtTokenName);
        this.authUser.next(null);
      }
    });
  }

  // Marker No Implicit Return
  get_sa_staff_group(): any {
    // get_sa_staff_group(): Promise<any> {
    let options = {
      headers: httpHeaders,
      withCredentials: true,
    };

    // let jwt = localStorage.getItem(this.jwtTokenName);
    // let jwt = this.cookieService.get(this.jwtTokenName);
    console.log("get_sa_staff: Userdaten" + JSON.stringify(this.authenticationService.userValue, null, 2));
    // let jwt = this.cookiemgmt.getCookie(this.jwtTokenName);
    let jwt = this.authenticationService.userToken;
    console.log(jwt);
    if (jwt && !this.jwtHelper.isTokenExpired(jwt)) {
      return this.httpClient
        .post(`${SERVER_URL}/restapi/get_sa_staff_group/`, "", options)
        .toPromise()
        // Marker No Implicit Return
        .then(
          (val): any => {
            try {
              // Benutzername ausgeben:
              console.log("Benutzergruppe: " + val);
              return val;
              /**
               * Token ist nicht valide also, Token löschen und ausloggen
               *
               */
            } catch (err) {
              /**
               * Es gab einen Fehler: Ausloggen (Sicherheit)
               */
              this.logout();
            }
          },
          (response) => {
            console.log("POST call in error", response);
            // localStorage.removeItem(this.jwtTokenName);
            // this.cookieService.delete(this.jwtTokenName);
            this.cookiemgmt.deleteCookie(this.jwtTokenName);
            this.logout();
          }
        );
    }
  }

  changeUserPassword(oldpw: string, newpw: string): Observable<any> {
    oldpw = this.md5(oldpw);
    newpw = this.md5(newpw);
    console.log(newpw);
    const body = new HttpParams()
      .set('oldpw', oldpw)
      .set('newpw', newpw)


    return this.httpClient
      .post(`${SERVER_URL}/restapi/changePassword/`, body.toString(), options)

      .pipe(
        take(1)
      )
    /*
              tap((response) => {
              take(1);
            })
          );
    */
  }



  /**
   * Diese Funktion dient dazu auf den Unterseiten zu überprüfen, ob der Benutzer
   * bereits einen gespeicherten token hat. Hat er einen gültigen Token, welcher noch nicht
   * abgelaufen ist, wird die Seite von der diese Funktion aufgerufen wurde geladen.
   * Ansonsten wird der Benutzer zur Login-Seite weitergeleitet.
   *
   * Diese Version der Funktion liefert ein Promise zurück und dient damit
   * als Basis für weitere, abhängig von dieser Funktion zu erfolgende Aufrufe
   *
   */

  async validate_token(): Promise<boolean> {
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: httpHeaders, withCredentials: true };
    const jwt = this.authenticationService.userToken;

    if (jwt && !this.jwtHelper.isTokenExpired(jwt)) {
      try {
        const response = await this.httpClient.post(`${SERVER_URL}/restapi/validate_token_extern/`, "", options).toPromise();
        return response && response["valid"] === "true";
      } catch {
        this.logout();
        return false;
      }
    } else {
      this.logout();
      return false;
    }
  }


  async validate_token_old(): Promise<any> {
    // Mit Hilfe von
    // https://stackoverflow.com/questions/39935721/how-to-return-observable-from-subscribe
    // zur Verwendung in ionViewCanEnter
    /**
     * Wert des gespeicherten Tokens
     */
    let httpHeaders = new HttpHeaders({
      // "Content-Type": "application/x-www-form-urlencoded",
      'Content-Type': 'application/json',
      // 'Authorization': "my-request-token",
      // 'Access-Control-Allow-Origin': 'http://localhost:8100',
      // 'Access-Control-Request-Headers': 'access-control-allow-origin, Authorization, Content-Type',
      //'Access-Control-Allow-Credentials': 'true'

      // 'Cache-Control': 'no-cache'
    });

    console.log("USEROBJECT AUSGABE: " + this.userSubject.value);


    /**
     * Definiert das options-Objekt, welche für den httpClient.post Aufruf benötigt wird.
     */
    let options = {
      headers: httpHeaders,
      withCredentials: true,
    };

    let jwt = "";
    // jwt = localStorage.getItem(this.jwtTokenName);
    // jwt = this.cookieService.get(this.jwtTokenName);
    // jwt = this.cookiemgmt.getCookie(this.jwtTokenName);


    jwt = this.authenticationService.userToken;

    // this.decode_jwt_token(jwt);

    if (jwt && !this.jwtHelper.isTokenExpired(jwt)) {
      console.log("Eingang Funktion validate_token");
      console.log("Token lautet (Fkt: validate_token):");
      console.log(jwt);
      console.log("Options lautet:");
      console.log(options);
      return this.httpClient
        .post(`${SERVER_URL}/restapi/validate_token_extern/`, "", options)
        .toPromise()
        .then((data) => {
          // console.log(data);
          if (data && data["valid"] == "true") {
            console.log("validate_token: Token Valide");
            return true;
          } else {
            console.log("validate_token: Token vorhanden aber nicht valide");
            const promise = new Promise((resolve, reject) => {
              resolve(false);
              this.logout();
            });
            return promise;
          }
        });
    } else {
      console.log("validate_token: Token nicht vorhanden");
      const promise = new Promise((resolve, reject) => {
        resolve(false);
        this.logout();
      });
      return promise;
    }
  }

  /**
   * Führt das logout durch.
   * Der Token wird gelöscht und es wird zur Login-Seite weitergeleitet
   */
  logout() {

    const promise_nachname = new Promise((resolve, reject) => {
      resolve(<any>localStorage.removeItem('nachname'));
    });

    const promise_vorname = new Promise((resolve, reject) => {
      resolve(<any>localStorage.removeItem('vorname'));
    });

    const promise_username = new Promise((resolve, reject) => {
      resolve(<any>localStorage.removeItem('username'));
    });



    const promise = new Promise((resolve, reject) => {
      // resolve(<any>localStorage.removeItem(this.jwtTokenName));
      // resolve(<any>this.cookieService.delete(this.jwtTokenName));
      resolve(this.cookiemgmt.deleteCookie(this.jwtTokenName))
    });
    promise.then(() => {
      this.authUser.next(null);
    });

    //localStorage.removeItem(this.jwtTokenName);
    // this.storage.remove(this.jwtTokenName).then(() => this.authUser.next(null));
  }


  /**
   * Diese Funktion ruft bei Superanton die Methode zum Ändern des Passwortes auf.
   * @param username 
   * @param password
   * @returns 
   */



  /**
   * Führt den Anmeldeprozess durch.
   * @param username Der Benutzername
   * @param password Das Passwort
   */
  login_formgroup(username: any, password: any): Observable<any> {
    console.log("Werte innerhalb von Auth login_formgroup:");
    // console.log(JSON.stringify(values));
    console.log(username);
    console.log(password);
    const userobject = {
      username: username,
      password: password,
    };
    // const body1 = "data=" + JSON.stringify(userobject);
    // let body2 = new URLSearchParams();
    // body2.set('username', username);
    // body2.set('password', password);

    /**
     * Erzeugt ein HttpParams request/response body
     * und setzt Eigenschaften
     * muss als body3.toString() übergeben werden
     */
    // Das Passwort soll als MD5 Hash übertragen werden.
    const body3 = new HttpParams()
      .set(`username`, username)
      .set(`password`, this.md5(password));
    // return this.httpClient.post(`${SERVER_URL}/restapi_login/anmelden`,body1,options)
    // return this.httpClient.post(`${SERVER_URL}/restapi/anmelden`, body3.toString(), options)
    return this.httpClient
      .post(`${SERVER_URL}/restapi/anmelden`, body3.toString(), options)

      .pipe(
        tap((jwt) => {
          this.save_name('nachname', jwt.nachname);
          this.save_name('vorname', jwt.vorname);
          this.save_name('username', username);
          this.handleJwtResponse(jwt.token);
          this.userSubject.next({
            nachname: jwt.nachname,
            vorname: jwt.vorname,
            jwtToken: jwt.token,
            gruppe: jwt.gruppe,
            maManageEdit: jwt.maManageEdit,
            proManageEdit: jwt.proManageEdit
          })
        })
      );
  }

  /**
   * Diese Funktion speichert den von der restapi zurückgegeben Token
   * und führt dann die next Funktion des ReplaySubjects aus.
   * Es wird also zur Home-Page oder zur Login-Seite weitergeleitet
   * @param jwt der von der restapi zurückgegebene Token aus der Funktion login_formgroup
   */
  private handleJwtResponse(jwt: string) {
    console.log("HandleJWTToken");
    console.log(jwt);
    const promise = new Promise((resolve, reject) => {
      // resolve(<any>localStorage.setItem(this.jwtTokenName, jwt));
      resolve(this.cookiemgmt.setCookie(this.jwtTokenName, jwt, 1));

      //       document.cookie = this.jwtTokenName + '=' + jwt;
      // resolve(<any>this.cookieService.set(this.jwtTokenName, jwt)); // set the cookies value

    });
    promise.then(() => {
      console.log("HandleJWTToken: Token gespeichert");
      this.authUser.next(jwt);
    });
  }

  private save_name(savename: string, name: string) {
    const promise = new Promise((resolve, reject) => {
      resolve(<any>localStorage.setItem(savename, name));
    });
  }


  // Diese Funktion berechnet aus einem String den MD5 Hashwert
  /**
  * MD5 (Message-Digest Algorithm)
  * http://www.webtoolkit.info/
  **/

  md5(string) {
    function RotateLeft(lValue, iShiftBits) {
      return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
    }

    function AddUnsigned(lX, lY) {
      var lX4, lY4, lX8, lY8, lResult;
      lX8 = (lX & 0x80000000);
      lY8 = (lY & 0x80000000);
      lX4 = (lX & 0x40000000);
      lY4 = (lY & 0x40000000);
      lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
      if (lX4 & lY4) {
        return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
      }
      if (lX4 | lY4) {
        if (lResult & 0x40000000) {
          return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
        } else {
          return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
        }
      } else {
        return (lResult ^ lX8 ^ lY8);
      }
    }

    function F(x, y, z) { return (x & y) | ((~x) & z); }
    function G(x, y, z) { return (x & z) | (y & (~z)); }
    function H(x, y, z) { return (x ^ y ^ z); }
    function I(x, y, z) { return (y ^ (x | (~z))); }

    function FF(a, b, c, d, x, s, ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    };

    function GG(a, b, c, d, x, s, ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    };

    function HH(a, b, c, d, x, s, ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    };

    function II(a, b, c, d, x, s, ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    };

    function ConvertToWordArray(string) {
      var lWordCount;
      var lMessageLength = string.length;
      var lNumberOfWords_temp1 = lMessageLength + 8;
      var lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
      var lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
      var lWordArray = Array(lNumberOfWords - 1);
      var lBytePosition = 0;
      var lByteCount = 0;
      while (lByteCount < lMessageLength) {
        lWordCount = (lByteCount - (lByteCount % 4)) / 4;
        lBytePosition = (lByteCount % 4) * 8;
        lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount) << lBytePosition));
        lByteCount++;
      }
      lWordCount = (lByteCount - (lByteCount % 4)) / 4;
      lBytePosition = (lByteCount % 4) * 8;
      lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
      lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
      lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
      return lWordArray;
    };

    function WordToHex(lValue) {
      var WordToHexValue = '', WordToHexValue_temp = '', lByte, lCount;
      for (lCount = 0; lCount <= 3; lCount++) {
        lByte = (lValue >>> (lCount * 8)) & 255;
        WordToHexValue_temp = '0' + lByte.toString(16);
        WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length - 2, 2);
      }
      return WordToHexValue;
    };

    function Utf8Encode(string) {
      string = string.replace(/\r\n/g, '\n');
      var utftext = '';

      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);

        if (c < 128) {
          utftext += String.fromCharCode(c);
        }
        else if ((c > 127) && (c < 2048)) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        }
        else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }

      return utftext;
    };

    var x = Array();
    var k, AA, BB, CC, DD, a, b, c, d;
    var S11 = 7, S12 = 12, S13 = 17, S14 = 22;
    var S21 = 5, S22 = 9, S23 = 14, S24 = 20;
    var S31 = 4, S32 = 11, S33 = 16, S34 = 23;
    var S41 = 6, S42 = 10, S43 = 15, S44 = 21;

    string = Utf8Encode(string);

    x = ConvertToWordArray(string);

    a = 0x67452301; b = 0xEFCDAB89; c = 0x98BADCFE; d = 0x10325476;

    for (k = 0; k < x.length; k += 16) {
      AA = a; BB = b; CC = c; DD = d;
      a = FF(a, b, c, d, x[k + 0], S11, 0xD76AA478);
      d = FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
      c = FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
      b = FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
      a = FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
      d = FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
      c = FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
      b = FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
      a = FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
      d = FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
      c = FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
      b = FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
      a = FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
      d = FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
      c = FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
      b = FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
      a = GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
      d = GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
      c = GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
      b = GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA);
      a = GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
      d = GG(d, a, b, c, x[k + 10], S22, 0x2441453);
      c = GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
      b = GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
      a = GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
      d = GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
      c = GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
      b = GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
      a = GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
      d = GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
      c = GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
      b = GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
      a = HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
      d = HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
      c = HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
      b = HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
      a = HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
      d = HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
      c = HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
      b = HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
      a = HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
      d = HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA);
      c = HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
      b = HH(b, c, d, a, x[k + 6], S34, 0x4881D05);
      a = HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
      d = HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
      c = HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
      b = HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
      a = II(a, b, c, d, x[k + 0], S41, 0xF4292244);
      d = II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
      c = II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
      b = II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
      a = II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
      d = II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
      c = II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
      b = II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
      a = II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
      d = II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
      c = II(c, d, a, b, x[k + 6], S43, 0xA3014314);
      b = II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
      a = II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
      d = II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
      c = II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
      b = II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
      a = AddUnsigned(a, AA);
      b = AddUnsigned(b, BB);
      c = AddUnsigned(c, CC);
      d = AddUnsigned(d, DD);
    }

    var temp = WordToHex(a) + WordToHex(b) + WordToHex(c) + WordToHex(d);

    return temp.toLowerCase();
  }

}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard';

const routes: Routes = [

  // {
  //   path: '', loadChildren: () => import('./pages/firstpage/firstpage.module').then(m => m.FirstpagePageModule),
  //   canActivate: [AuthGuard]
  // },

  // { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },

  // otherwise redirect to home
  // { path: '**', redirectTo: '' },

  {
    path: '',
    loadChildren: () => import('./tabs/tabs/tabs.module').then(m => m.TabsPageModule), canActivate: [AuthGuard]
  },



  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full'
  },
  {
    path: 'tabs',
    redirectTo: 'tabs',
    pathMatch: 'full'
  },


  /*
  {
    path: '',
    redirectTo: 'einsatzplanung',
    pathMatch: 'full'
  },
  */

  /*
  {
    path: 'einsatzplanung',
    loadChildren: () => import('./pages/einsatzplanung/einsatzplanung.module').then( m => m.EinsatzplanungPageModule)
  },
  */

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then(m => m.LogoutPageModule)
  },

  {
    path: 'mitarbeiter',
    loadChildren: () => import('./pages/mitarbeiter/mitarbeiter.module').then(m => m.MitarbeiterPageModule), canActivate: [AuthGuard]
  },

  {
    path: 'projekte',
    loadChildren: () => import('./pages/projekte/projekte.module').then(m => m.ProjektePageModule), canActivate: [AuthGuard]
  },

  /*
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'tab1',
    loadChildren: () => import('./tabs/tab1/tab1.module').then( m => m.Tab1PageModule)
  },
  {
    path: 'tab2',
    loadChildren: () => import('./tabs/tab2/tab2.module').then( m => m.Tab2PageModule)
  },
  {
    path: 'tab3',
    loadChildren: () => import('./tabs/tab3/tab3.module').then( m => m.Tab3PageModule)
  },
  */

  /*
  {
    path: 'remedydienst',
    loadChildren: () => import('./tabs/remedydienst/remedydienst.module').then( m => m.RemedydienstPageModule)
  },
  */
  {
    path: 'remedydienst',
    loadChildren: () => import('./pages/remedydienst/remedydienst.module').then(m => m.RemedydienstPageModule), canActivate: [AuthGuard]
  },

  // {
  //   path: 'datenschutz',
  //   loadChildren: () => import('./pages/datenschutz/datenschutz.module').then(m => m.DatenschutzPageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'impressum',
    loadChildren: () => import('./pages/impressum/impressum.module').then(m => m.ImpressumPageModule)
  },
  {
    path: 'tmp',
    loadChildren: () => import('./pages/tmp/tmp.module').then(m => m.TmpPageModule)
  },
  {
    path: 'request-absence',
    loadChildren: () => import('./pages/request-absence/request-absence.module').then(m => m.RequestAbsencePageModule), canActivate: [AuthGuard]
  },
  {
    path: 'retract-absence',
    loadChildren: () => import('./pages/retract-absence/retract-absence.module').then(m => m.RetractAbsencePageModule), canActivate: [AuthGuard]
  },
  {
    path: 'announce-availability',
    loadChildren: () => import('./pages/announce-availability/announce-availability.module').then(m => m.AnnounceAvailabilityPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'retract-availability',
    loadChildren: () => import('./pages/retract-availability/retract-availability.module').then(m => m.RetractAvailabilityPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/password-reset/password-reset.module').then(m => m.PasswordResetPageModule)
  },
  {
    path: 'settings-overview',
    loadChildren: () => import('./pages/settings-overview/settings-overview.module').then(m => m.SettingsOverviewPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'settings-account-ma',
    loadChildren: () => import('./pages/settings-account-ma/settings-account-ma.module').then(m => m.SettingsAccountMaPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'settings-account-admin',
    loadChildren: () => import('./pages/settings-account-admin/settings-account-admin.module').then(m => m.SettingsAccountAdminPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'settings-account-pl',
    loadChildren: () => import('./pages/settings-account-pl/settings-account-pl.module').then(m => m.SettingsAccountPlPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'password-reset-intern',
    loadChildren: () => import('./pages/password-reset-intern/password-reset-intern.module').then(m => m.PasswordResetInternPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'dienstplanung-pl',
    loadChildren: () => import('./pages/dienstplanung-pl/dienstplanung-pl.module').then(m => m.DienstplanungPlPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'set-kw-date-modal',
    loadChildren: () => import('./pages/set-kw-date-modal/set-kw-date-modal.module').then(m => m.SetKwDateModalPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'show-date-picker',
    loadChildren: () => import('./pages/show-date-picker/show-date-picker.module').then(m => m.ShowDatePickerPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'testpage',
    loadChildren: () => import('./pages/testpage/testpage.module').then(m => m.TestpagePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'firstpage',
    loadChildren: () => import('./pages/firstpage/firstpage.module').then(m => m.FirstpagePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'select-project-dp-modal',
    loadChildren: () => import('./pages/select-project-dp-modal/select-project-dp-modal.module').then(m => m.SelectProjectDpModalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'select-dienstplanung-pl',
    loadChildren: () => import('./pages/select-dienstplanung-pl/select-dienstplanung-pl.module').then(m => m.SelectDienstplanungPlPageModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'projektuebersicht',
    loadChildren: () => import('./pages/projektuebersicht/projektuebersicht.module').then(m => m.ProjektuebersichtPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'eingabe-dp-modal',
    loadChildren: () => import('./pages/eingabe-dp-modal/eingabe-dp-modal.module').then(m => m.EingabeDpModalPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'ma-bearbeiten',
    loadChildren: () => import('./pages/ma-bearbeiten/ma-bearbeiten.module').then(m => m.MaBearbeitenPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'select-mitarbeiter-dp-modal',
    loadChildren: () => import('./pages/select-mitarbeiter-dp-modal/select-mitarbeiter-dp-modal.module').then(m => m.SelectMitarbeiterDpModalPageModule)
  },
  {
    path: 'projekt-bearbeiten',
    loadChildren: () => import('./pages/projekt-bearbeiten/projekt-bearbeiten.module').then(m => m.ProjektBearbeitenPageModule)
  },
  {
    path: 'data-export-modal',
    loadChildren: () => import('./pages/data-export-modal/data-export-modal.module').then(m => m.DataExportModalPageModule)
  },
  {
    path: 'data-export-preview-modal',
    loadChildren: () => import('./pages/data-export-preview-modal/data-export-preview-modal.module').then(m => m.DataExportPreviewModalPageModule)
  },
  {
    path: 'mitarbeiter-einsatz-modal',
    loadChildren: () => import('./pages/mitarbeiter-einsatz-modal/mitarbeiter-einsatz-modal.module').then(m => m.MitarbeiterEinsatzModalPageModule)
  },
  {
    path: 'projekt-mitarbeiter-select-modal',
    loadChildren: () => import('./pages/projekt-mitarbeiter-select-modal/projekt-mitarbeiter-select-modal.module').then(m => m.ProjektMitarbeiterSelectModalPageModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsPageModule)
  },

  /*
  {
    path: 'projektuebersicht',
    loadChildren: () => import('./tabs/projektuebersicht/projektuebersicht.module').then( m => m.ProjektuebersichtPageModule)
  }
  */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
